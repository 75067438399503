document.addEventListener("DOMContentLoaded",function(){

    /**
     * Get Show/Hide the company details
     */
    let companyTypes = document.querySelectorAll('.company_type');
    for (let idx = 0; idx < companyTypes.length; idx++) {
        companyTypes[idx].addEventListener('change', function(e) {

            console.log("Company Type has been changed");
            const companyType = e.target.value;

            if(companyType==="i"){

                let companySelect = document.querySelector('#company_id');
                if(companySelect){
                    companySelect.closest('div').classList.add("is-hidden");
                }

                let companyFields = document.querySelectorAll('.company_info');
                for(let companyField of companyFields){
                    companyField.closest('div').classList.add("is-hidden");
                }

            } else if(companyType==="r"){

                let companySelect = document.querySelector('#company_id');
                if(companySelect){
                    companySelect.closest('div').classList.remove("is-hidden");
                }

                let companyFields = document.querySelectorAll('.company_info');
                for(let companyField of companyFields){
                    companyField.closest('div').classList.add("is-hidden");
                }

            } else {

                let companySelect = document.querySelector('#company_id');
                if(companySelect){
                    companySelect.closest('div').classList.add("is-hidden");
                }

                let companyFields = document.querySelectorAll('.company_info');
                for(let companyField of companyFields){
                    companyField.closest('div').classList.remove("is-hidden");
                }
            }

        });
    }

    let companySelect = document.getElementById('company_id');
    if(companySelect) {
        companySelect.addEventListener('change', function(e) {
            console.log("Company Select has changed: ",e.target.value);
        });
    }

    // const nameField = document.querySelector('#name');
    // if(nameField) {
    //     nameField.addEventListener('keydown', function(e) {
    //         if(nameField.classList.contains('is-danger')) {
    //             nameField.classList.remove('is-danger');
    //             document.querySelector('#name_error').style.visibility = 'hidden';
    //         }
    //     });
    // }

    // const emailField = document.querySelector('#email');
    // if(emailField) {
    //     emailField.addEventListener('keydown', function(e){
    //         if(emailField.classList.contains('is-danger')) {
    //             emailField.classList.remove('is-danger');
    //             document.querySelector('#email_error').style.visibility = 'hidden';
    //         }
    //     });
    // }

    // const passwordField = document.querySelector('#password');
    // if(passwordField) {
    //     passwordField.addEventListener('keydown', function(e){
    //        passwordField.classList.remove('is-danger');
    //        document.querySelector('#password_error').style.visibility = 'hidden';
    //     });
    // }

    // const confirmField = document.querySelector('#password_confirmation');
    // if(confirmField) {
    //     confirmField.addEventListener('keydown', function(e){
    //        confirmField.classList.remove('is-danger');
    //        document.querySelector('#confirm_error').style.visibility = 'hidden';
    //     });
    // }

    // const termsField = document.querySelector('#terms_check');
    // if(termsField) {
    //     termsField.addEventListener('click', function(e){
    //        termsField.classLis`t`.remove('is-danger');
    //        document.querySelector('#terms_error').style.visibility = 'hidden';
    //     });
    // }

    const radios = document.querySelectorAll("input[name='role']");
    // Add event listeners to toggle the icon class
    radios.forEach((radio) => {
        radio.addEventListener("change", () => {
            // Reset all icons to the default
            document.querySelectorAll("i").forEach((icon) => {
                icon.classList.remove("fa-circle-check");
                icon.classList.add("fa-circle");
            });
            // Update the icon for the selected card
            const selectedLabel = radio.closest("label");
            const icon = selectedLabel.querySelector("i");
            icon.classList.remove("fa-circle");
            icon.classList.add("fa-circle-check");
        });
    });
});